import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e36cbbc2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "black-header"
};
const _hoisted_2 = {
  class: "black-nav-pc"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "search-input"
};
const _hoisted_5 = {
  class: "black-nav"
};
const _hoisted_6 = ["onClick"];
import { store } from "../utils/store.js";
import { ref, getCurrentInstance, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import AdsPictures from "./AdsPictures.vue";
import api from "@/api/shipin2/index.js";
export default {
  __name: 'Header',
  props: {
    list: {}
  },
  emits: ["search"],
  setup(__props, {
    emit
  }) {
    const isDev = getCurrentInstance()?.appContext.config.globalProperties.$isDev;
    const jump = url => {
      window.location.href = url;
    };
    const route = useRoute();
    const router = useRouter();
    const value = ref("");
    const adList = ref([]);
    value.value = route.query.keyword;
    const search = () => {
      if (!value.value) {
        return alert("请输入关键词");
      }
      if (route.query.keyword) {
        emit("search", value.value);
      } else {
        router.push({
          path: "more",
          query: {
            keyword: value.value
          }
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_image, {
        class: "img",
        fit: "cover",
        src: _unref(store).config.h5_logo
      }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["nav-item", (_ctx.$route.query.title === item.title || item.title === '首页' && !_ctx.$route.query.title && _ctx.$route.path === '/home') && 'active']),
          onClick: $event => item.url ? jump(item.url) : _ctx.$emit('query', item)
        }, _toDisplayString(_unref(isDev) ? "分类" : item.title), 11, _hoisted_3);
      }), 128))]), _createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => value.value = $event),
        type: "text",
        placeholder: "请输入搜索关键词"
      }, null, 512), [[_vModelText, value.value]]), _createVNode(_component_van_icon, {
        class: "icon",
        name: "search",
        size: "20",
        onClick: search
      })])]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["nav-item", (_ctx.$route.query.title === item.title || item.title === '首页' && !_ctx.$route.query.title && _ctx.$route.path === '/home') && 'active']),
          onClick: $event => item.url ? jump(item.url) : _ctx.$emit('query', item)
        }, _toDisplayString(_unref(isDev) ? "分类" : item.title), 11, _hoisted_6);
      }), 128))])], 64);
    };
  }
};