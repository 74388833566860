import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67e9aa04"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "img-div"
};
const _hoisted_3 = {
  class: "mask"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "mask-div"
};
const _hoisted_6 = {
  class: "watch-times"
};
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = {
  key: 0,
  style: {
    "text-align": "center"
  }
};
import { getCurrentInstance, computed } from 'vue';
import { store } from '../utils/store';
import AdsPictures from './AdsPictures.vue';
import { useRoute, useRouter } from "vue-router";
export default {
  __name: 'VideoList',
  props: {
    list: {}
  },
  emits: ['query'],
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const isDev = getCurrentInstance()?.appContext.config.globalProperties.$isDev;
    const clickImg = item => {
      window.open(item.url);
    };

    // 判断是否有广告：猜你喜欢视频列表插入广告 165 x 108.9 
    const adsList10 = computed(() => {
      let list = store.adsList.filter(v => v.class_id == 10);
      return list.length;
    });

    // 判断是首页
    const isHome = computed(() => {
      // console.log('route', route)
      if (route.path == '/home' && route.query.title == undefined) {
        return true;
      } else {
        return false;
      }
    });
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: _normalizeClass(["video-list", _ctx.$route.query.onlytTopic === '1' && 'onlytTopic'])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [index == __props.list.length - 1 && adsList10.value && isHome.value ? (_openBlock(), _createBlock(AdsPictures, {
          key: 0,
          type: 10,
          number: 1,
          random: 1
        })) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: $event => _ctx.$emit('query', item)
        }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
          class: "img",
          fit: "cover",
          "lazy-load": "",
          src: _unref(store).isDev() ? require('@/assets/logo.png') : item.upload_thumb
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(store).isDev() ? '标题' : item.title), 1), _createElementVNode("div", null, [_createTextVNode(" 瓜农 · "), _createElementVNode("span", null, _toDisplayString(item.create_date), 1), _createTextVNode(" · 今日吃瓜，人人吃瓜 ")])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_van_icon, {
          name: "eye-o"
        }), _createTextVNode(" " + _toDisplayString(item.watchtimes), 1)])])]), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(store).isDev() ? '标题' : item.title), 1)], 8, _hoisted_1))], 64);
      }), 128))], 2), !__props.list || !__props.list.length ? (_openBlock(), _createElementBlock("p", _hoisted_8, "暂无数据")) : _createCommentVNode("", true)], 64);
    };
  }
};