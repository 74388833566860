import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import install from './utils/install';
import 'animate.css/animate.min.css';
import { Lazyload } from 'vant';
const app = createApp(App);
app.use(Lazyload);

import { Search, Skeleton, SkeletonTitle, SkeletonImage, SkeletonAvatar, SkeletonParagraph } from 'vant';

app.use(Search);
app.use(Skeleton);
app.use(SkeletonTitle);
app.use(SkeletonImage);
app.use(SkeletonAvatar);
app.use(SkeletonParagraph);
app.use(install);
app.use(router);
app.mount('#app');

// 一下增加js统计代码

/**
 * 将字符串中的特殊字符转义为 HTML 实体
 * @param {string} str - 需要转义的字符串
 * @returns {string} - 转义后的字符串
 */
const unescapeHTML = (escapedString) => {
	var map = {
		'&lt;': '<',
		'&gt;': '>',
		'&amp;': '&',
		'&quot;': '"',
		'&#39;': "'"
	};

	return escapedString.replace(/&lt;|&gt;|&amp;|&quot;|&#39;/g, function (m) {
		return map[m];
	});
};

import { store } from './utils/store.js';
import api from '@/api/shipin2/index.js';
import { ref } from 'vue';

var config = ref({});
const queryWebsiteConfiguration = async () => {
	const res = await api.queryWebsiteConfiguration();
	document.title = res.data.logo_title;
	console.log('res.data', res.data);
	config.value = res.data;
	store.config = store.config;
};
queryWebsiteConfiguration();
router.afterEach((to, from, next) => {
	// console.log('config', config);
	loaddingCnzz();

	loadding51La(); // 51拉js代码。
});

const loaddingCnzz = () => {
	setTimeout(() => {
		if (config.value?.statistics_js && config.value?.statistics_js.length > 0) {
			// alert(123);
			let tj = unescapeHTML(config.value?.statistics_js);
			// console.log("tj", tj,typeof(tj));
			let newStr = tj.replace(/<script>/gi, '').replace(/<\/script>/gi, '');
			// alert(newStr)
			eval(newStr);
		}
	}, 50);
};

const loadding51La = () => {
	console.log('加载51拉js代码。');
	!(function (p) {
		'use strict';
		!(function (t) {
			var s = window,
				e = document,
				i = p,
				c = ''.concat('https:' === e.location.protocol ? 'https://' : 'http://', 'sdk.51.la/js-sdk-pro.min.js'),
				n = e.createElement('script'),
				r = e.getElementsByTagName('script')[0];
			(n.type = 'text/javascript'), n.setAttribute('charset', 'UTF-8'), (n.async = !0), (n.src = c), (n.id = 'LA_COLLECT'), (i.d = n);
			var o = function () {
				s.LA.ids.push(i);
			};
			s.LA ? s.LA.ids && o() : ((s.LA = p), (s.LA.ids = []), o()), r.parentNode.insertBefore(n, r);
		})();
	})({ id: 'KZVGOtiomBeRCJOA', ck: 'KZVGOtiomBeRCJOA' });
};
