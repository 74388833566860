import axios from 'axios';
import { channelCode } from '@/js/channel';

// process.env.VUE_APP_VIDEO1_API_URL;
const instance = axios.create({
	baseURL: process.env.VUE_APP_VIDEO2_API_URL,
	timeout: 10000
});

// 添加请求拦截器
instance.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		const channel = channelCode;
		config.params = {
			channel,
			...config.params
		};
		return config;
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
instance.interceptors.response.use(
	function (response) {
		// 对响应数据做点什么
		return response.data;
	},
	function (error) {
		// const { status } = error.response;
		// if (status !== 200) {
		// 	location.reload(); // 页面刷新
		// }

		// 对响应错误做点什么
		return Promise.reject(error);
	}
);

export default instance;
