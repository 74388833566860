import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue";
import api from "@/api/shipin2/index.js";
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

// 根据渠道码判断是否显示公告
import { store } from '../utils/store.js';
import { channelCode } from '@/js/channel';
export default {
  __name: 'CommonHeader',
  props: {
    categoryNavShow: {
      default: true //nav是否显示
    },

    noticeBarShow: {
      default: true //公告是否显示
    }
  },

  emits: ['query'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const hasqudao = store.filterQudaolist.includes(channelCode);
    const router = useRouter();
    const route = useRoute();
    const oldScrollTop = ref(0);
    const isHide = ref(false);
    const scrollTop = ref(0);
    const showFixNav = ref(false);
    const cateRed = ref();
    const scrolling = event => {
      scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      // 设置上边界 未超过显示
      if (scrollTop.value < 100) {
        showFixNav.value = false;
        return isHide.value = false;
      }
      if (scrollTop.value > 100) {
        showFixNav.value = true;
      }

      // // 返回页面的时候设置显示
      // if (scrollTop.value === 0) {
      //   return scrollTop.value = false
      // }

      // 设置下边界 滑动到下边界后隐藏顶部 
      if (scrollTop.value + document.documentElement.clientHeight >= document.body.scrollHeight - 100) {
        return isHide.value = true;
      }

      // 滚动条滚动的距离
      let scrollStep = scrollTop.value - oldScrollTop.value;
      // 更新——滚动前，滚动条距文档顶部的距离
      oldScrollTop.value = scrollTop.value;
      if (scrollTop.value + document.documentElement.clientHeight === document.body.scrollHeight) {
        return isHide.value = false;
      }
      if (scrollStep < 0) {
        isHide.value = false;
      } else {
        isHide.value = true;
      }
    };
    const categoryList = ref([]);
    const notice = ref([]);
    window.addEventListener("scroll", scrolling);

    // 查询公告
    const queryAnnouncement = async () => {
      const res = await api.queryAnnouncement();
      notice.value = res.data;
    };
    const queryList = item => {
      emits('query', item);
    };

    // 查询分类列表
    const queryClassificationList = async () => {
      const res = await api.queryClassificationList();
      res.data.splice(1, 0, {
        "tid": 0,
        "id": 0,
        "type": "hot",
        "url": "",
        "name": "热门",
        "title": "热门",
        "only_topic": "0"
      });
      categoryList.value = res.data;
    };
    onMounted(() => {
      queryAnnouncement();
      queryClassificationList();
      isHide.value = false;
    });
    return (_ctx, _cache) => {
      const _component_HeaderNav = _resolveComponent("HeaderNav");
      const _component_SearchInput = _resolveComponent("SearchInput");
      const _component_AdsPictures = _resolveComponent("AdsPictures");
      const _component_CategoryNav = _resolveComponent("CategoryNav");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_notice_bar = _resolveComponent("van-notice-bar");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: _normalizeClass([{
          isHide: isHide.value,
          'isHome': _ctx.$route.path === '/home' && showFixNav.value ? true : false
        }, "home-fixed"])
      }, [_createVNode(_component_HeaderNav, {
        onQuery: queryList
      })], 2), _createElementVNode("div", {
        class: _normalizeClass(["common-header", {
          'isHome': _ctx.$route.path === '/home' && isHide.value ? true : false
        }])
      }, null, 2), _ctx.$route.path === '/search' ? (_openBlock(), _createBlock(_component_SearchInput, {
        key: 0
      })) : _createCommentVNode("", true), _ctx.$route.path === '/home' && _ctx.$route.query.title == undefined ? (_openBlock(), _createBlock(_component_AdsPictures, {
        key: 1,
        type: 1,
        number: 3,
        random: true
      })) : _ctx.$route.path === '/home' && _ctx.$route.query.title != undefined ? (_openBlock(), _createBlock(_component_AdsPictures, {
        key: 2,
        type: 23,
        number: 3,
        random: true
      })) : _ctx.$route.path === '/details' ? (_openBlock(), _createBlock(_component_AdsPictures, {
        key: 3,
        type: 27,
        number: 3,
        random: true
      })) : _createCommentVNode("", true), _withDirectives(_createVNode(_component_CategoryNav, {
        class: _normalizeClass({
          'isHome': _ctx.$route.path === '/home' && showFixNav.value ? true : false
        }),
        list: categoryList.value,
        onQuery: queryList,
        ref_key: "cateRed",
        ref: cateRed
      }, null, 8, ["class", "list"]), [[_vShow, __props.categoryNavShow]]), _withDirectives(_createVNode(_component_van_notice_bar, {
        class: "notice",
        "left-icon": "volume-o",
        scrollable: "",
        background: "#cce5ff",
        color: "#004085"
      }, {
        "left-icon": _withCtx(() => [_createVNode(_component_van_icon, {
          name: "volume"
        })]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notice.value, (item, index) => {
          return _openBlock(), _createElementBlock("span", {
            key: index
          }, _toDisplayString(item.content), 1);
        }), 128))]),
        _: 1
      }, 512), [[_vShow, __props.noticeBarShow && !_unref(hasqudao)]])], 64);
    };
  }
};