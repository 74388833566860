import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9339af00"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
import { onMounted, computed } from "vue";
import { watch, ref, inject, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api/shipin2/index.js";
import { nextTick } from "vue";
import { store } from '../utils/store';
import { channelCode } from '@/js/channel';

// 根据渠道码判断是否显示:福利导航
// import { store } from '../utils/store.js';
// import { channelCode } from '@/js/channel';

export default {
  __name: 'CategoryNav',
  props: {
    list: {}
  },
  emits: ['query', 'change'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const openurl = url => {
      window.location.href = url;
    };
    const route = useRoute();
    const router = useRouter();
    const active = ref();
    const tabs = ref();

    // 分类切换
    const change = ({
      name
    }) => {
      console.log('change-name', name);
      // const item = props.list[name]
      const item = modifiedList.value[name];
      if (item.url) {
        // 有url 跳转
        window.location.href = item.url;
      } else if (name === 0) {
        // 首页
        router.push('/home');
      } else if (name === 1) {
        // 热门
        router.push({
          path: 'home',
          query: {
            title: '热门'
          }
        });
      } else {
        // 首页其它分类
        router.push({
          path: 'home',
          query: {
            title: item.title,
            onlytTopic: item.only_topic
          }
        });
      }
    };
    const changeTabs = () => {
      active.value = props.list.findIndex(v => v.title === route.query.title);
      nextTick(() => {
        setTimeout(() => {
          tabs.value?.resize();
        }, 1000);
      });
    };
    const hasqudao = store.filterQudaolist.includes(channelCode);

    // 创建计算属性 modifiedList
    const modifiedList = computed(() => {
      // console.log('channelCode----->', channelCode)
      // 如果是渠道海角77， 清除福利导航
      if (hasqudao) {
        return props.list;
      }

      // 创建一个本地副本
      const newList = [...props.list];
      // 在第一个元素后面插入一个值
      if (newList.length > 0) {
        let v = {
          id: 0,
          title: '福利导航',
          url: 'https://51dhz.com/'
        };
        // 将对象转换为响应式对象
        let reactiveV = reactive(v);
        newList.splice(1, 0, reactiveV);
      }
      console.log('modifiedList', newList);
      return newList;
    });
    watch(() => props.list, () => {
      if (route.path === '/home') {
        changeTabs();
      }
    });
    watch(route, v => {
      if (route.path === '/home') {
        if ([v.query.title, v.query.classify, v.query.hot].every(v => !v) && route.path === '/home') {
          changeTabs();
        }
      }
    });
    const cateList = ref([]);

    // 查询分类列表
    const getCateList = async () => {
      const res = await api.getCateList();
      cateList.value = res.data;
    };
    onMounted(() => {
      getCateList();
      if (route.path === '/home') {
        changeTabs();
      }
    });
    return (_ctx, _cache) => {
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createBlock(_component_van_tabs, {
        class: "tabs",
        ref_key: "tabs",
        ref: tabs,
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        "line-height": "0",
        "line-width": "0",
        onClickTab: change
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(modifiedList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_tab, {
            name: index,
            key: index
          }, _createSlots({
            _: 2
          }, [index == 1 && !_unref(store).filterQudaolist.includes(_unref(channelCode)) ? {
            name: "title",
            fn: _withCtx(() => [_createElementVNode("div", {
              class: "fldh",
              onClick: $event => openurl(item.url)
            }, [_createElementVNode("span", null, _toDisplayString(_unref(store).isDev() ? 'title' : item.title), 1)], 8, _hoisted_1)]),
            key: "0"
          } : {
            name: "title",
            fn: _withCtx(() => [false ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass({
                addColor: item.only_topic === '1' && true
              })
            }, _toDisplayString(item.title), 3)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_unref(store).isDev() ? 'title' : item.title), 1)]),
            key: "1"
          }]), 1032, ["name"]);
        }), 128))]),
        _: 1
      }, 8, ["active"]);
    };
  }
};