import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ae91742"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search"
};
const _hoisted_2 = {
  class: "search-input"
};
import { store } from "../utils/store";
import { ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
export default {
  __name: 'SearchInput',
  setup(__props) {
    const router = useRouter();
    const keysValue = ref('');
    const search = () => {
      router.push({
        path: "more",
        query: {
          keyword: keysValue.value
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_search = _resolveComponent("van-search");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, "牢记本站域名: " + _toDisplayString(_unref(store).config.website), 1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_search, {
        modelValue: keysValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => keysValue.value = $event),
        placeholder: "输入关键词"
      }, {
        "right-icon": _withCtx(() => []),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", {
        class: "search-button",
        onClick: search
      }, "搜索")])]);
    };
  }
};